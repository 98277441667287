import './App.css';
import ClaireForm from './ClaireForm';

function App() {
  return (
    <div className="App">
      <header className="App-header App-component">
        <p>
          Prototype Claire
        </p>
      </header>
      <body className='App-body App-component'>
        <ClaireForm />
      </body>
    </div>
  );
}

export default App;
