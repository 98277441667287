import React, { useState, useEffect } from 'react';

const CircularProgressBar = ({ isLoading, progress }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setIsVisible(true);
    } else {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 500); // Delay hiding the progress bar for smooth transition
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="circular-progress-bar">
    <svg class = "button" expanded = "true" height = "100px" width = "100px">
        <circle cx = "50%" cy = "50%" r = "35%" stroke = "#000000" stroke-width = "10%" fill = "none"/>
        <circle class = "innerCircle" cx = "50%" cy = "50%" r = "25%" fill = "#000000">
        <animate attributeName="r" begin="0s" dur="1s" repeatCount="indefinite" from="5%" to="25%"/>
        </circle>
    </svg>
    </div>
  );
};

export default CircularProgressBar;