import axios from 'axios';
import CircularProgressBar from './CircularProgessBar';
const { useState } = require("react");

const PROTOCOL =  "https";
const DOMAIN =  "aidev.bluecodesolutions.com:8080/";
const ENDPOINT = "generateTasks";

function ClaireForm () {
    
    const [inputTextArea, setInputTextArea] = useState("");
    const [outputTextArea, setoutputTextArea] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await setIsLoading(true);
        await setoutputTextArea("");
        try {
            const URL = PROTOCOL + "://" + DOMAIN + ENDPOINT;
            const response = await axios.post(URL, { 
                inputTextArea: inputTextArea 
            });
            setoutputTextArea(response.data);
        } catch (error) {
            console.error(error);
        }
        await setIsLoading(false);
    }

    return (
        <form onSubmit={e => handleSubmit(e)}>
            <div className="App-container">
                <div className="App-textarea">
                    <label>Text</label>
                    <br />
                    <textarea value={inputTextArea} onChange={e => setInputTextArea(e.target.value)} />
                </div>
                <div className="App-textarea">
                    <label>Tasks</label>
                    <br />
                    {isLoading ? <CircularProgressBar isLoading={isLoading}/> : <textarea value={outputTextArea} onChange={e => setoutputTextArea(e.target.value)} /> }
                </div>
            </div>
            <br />
            {isLoading ? null : <input type="submit" value="Submit" />}
        </form>
    )

}

export default ClaireForm;
